/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import type { AppProps /*, AppContext */ } from 'next/app';
import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';
import 'styles/globals.css'
import { QueryClientProvider } from 'react-query'
import { BackgroundColor } from 'utils/ColorUtils';
import { queryClient } from 'services/queryClient';
import { SegmentHOC } from 'tags/SegmentTag'
import Modal from 'elements/Modal';
import Notifications from 'elements/Notifications';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  backgroundColor?: BackgroundColor; // Maybe a Theme soon?
  isHeaderHidden?: boolean;
  isHeaderTransparent?: boolean;
  isFooterHidden?: boolean;
  isFooterTransparent?: boolean;

  // We then pass the above to the layout
  getLayout?: (
    page: ReactElement,
    backgrounColor?: BackgroundColor,
    isHeaderHidden?: boolean,
    isHeaderTransparent?: boolean,
    isFooterHidden?: boolean,
    isFooterTransparent?: boolean
  ) => ReactNode;
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  // @ts-ignore
  // getLayout is used to introduce Persistent Layout
  // Ref. https://nextjs.org/docs/basic-features/layouts
  // Ref. https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  const getLayout = Component.getLayout || ((page) => page);

  const backgroundColor = Component.backgroundColor || BackgroundColor.White;
  const isHeaderHidden = Component.isHeaderHidden || false;
  const isHeaderTransparent = Component.isHeaderTransparent || false;
  const isFooterHidden = Component.isFooterHidden || false;
  const isFooterTransparent = Component.isFooterTransparent || false;

  return getLayout(
    <SegmentHOC>
      <ParallaxProvider>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
          <Modal />
          <Notifications />
        </QueryClientProvider>
      </ParallaxProvider>
    </SegmentHOC>, 
    backgroundColor,
    isHeaderHidden,
    isHeaderTransparent,
    isFooterHidden,
    isFooterTransparent
  )
}

export default MyApp
